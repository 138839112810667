<template>
	<div class="saving-screen fixed" v-if="isWorking">
		<span>İşlem yapılıyor, lütfen bekleyin....</span>
	</div>
	<div class="work-end fixed" v-if="endModal">
		<span>{{ endComment }}</span>
	</div>
	<template v-if="userModal">
		<div class="work-end fixed" v-if="newUserList !== ''">
			<p v-html="newUserList"></p>
		</div>
		<div class="work-end fixed" v-else>
			<span>Yeni kullanıcı eklenmedi.</span>
		</div>
	</template>
	<r-data-list
		:headdata="headdata"
		:listdata="listData.data"
		:opt="opts"
		v-if="loaded"
		:searchfilter="searchfilter"
		v-model:qu="q"
		><template v-slot:rowextrabtn="{ datarow }">
			<r-btn
				@click="changePasswordM(datarow)"
				bgcolor="#003642"
				v-if="datarow.type === 6"
				>Şifre Değiştir</r-btn
			>
		</template>
		<template v-slot:titleextrabuttonsafter>
			<r-btn bgcolor="#55110d" @click="OpenHazirKaliplar">Hazır Kalıplar</r-btn>
			<r-btn bgcolor="#55110d" @click="getForumUsers"
				>Forum Kullanıcılarını Çek</r-btn
			>
			<r-btn bgcolor="#55110d" @click="openSettings">Diğer Ayarlar</r-btn>
		</template>
	</r-data-list>

	<r-panel
		:title="userData.name + ' Kullanıcı Bilgileri'"
		v-if="userPanel"
		v-model:isactive="userPanel"
		pos="center"
	>
		<template v-slot:titlebuttons>
			<r-btn bgcolor="#15880d" @click="saveUser" :disabled="canSave">{{
				addUser ? 'Kullanıcı Ekle' : 'Kaydet'
			}}</r-btn>
		</template>
		<r-flex>
			<r-input v-model="userData.username" label="Kullanıcı Adı" />
			<r-input v-model="userData.name" label="Adı" />
			<r-input v-model="userData.email" label="Eposta Adresi" />
			<r-select
				v-model="userData.type"
				label="Kullanıcı Tipi"
				:data="userTypeList"
				txt="txt"
				val="val"
			/>
			<r-select
				v-model="userData.categoryId"
				label="Branş"
				:data="catList.data"
				txt="title"
				val="id"
			>
				<template v-slot:itemtxt="{ data }">
					{{ data.id + '_' + data.title }}
				</template>
			</r-select>
			<r-flex
				><span style="color:red"
					>Lütfen branş değiştirmeden önce varsa hocanın atanmış ders notlarını
					kaldırıp daha sonra branş değiştirme işlemini yapınız.</span
				></r-flex
			>
			<r-input
				v-model="userData.password"
				label="Şifre"
				v-if="addUser"
				type="password"
			/>
			<r-input
				v-model="passwordAgain"
				label="Şifre Tekrar"
				v-if="addUser"
				type="password"
			/>
			<r-flex class="r-mt-16">Bölüm İzinleri</r-flex>
			<r-flex>
				<r-flex flx="6">
					<r-checkbox
						v-model="userData.authorizations"
						label="Hesap Yönetimi"
						val="0"/>
					<r-checkbox
						v-model="userData.authorizations"
						label="Sorular"
						:val="1"/>
					<r-checkbox
						v-model="userData.authorizations"
						label="Soru Kategorileri"
						:val="2"/>
					<r-checkbox
						v-model="userData.authorizations"
						label="Ders Notları"
						:val="4"/>
					<r-checkbox
						v-model="userData.authorizations"
						label="Ders Notu Atama"
						:val="5"/>
					<r-checkbox
						v-model="userData.authorizations"
						label="Loglar"
						:val="6"/>
					<r-checkbox v-model="userData.authorizations" label="Export" :val="8"
				/></r-flex>
			</r-flex>

			<r-flex>
				<r-btn bgcolor="#15880d" @click="saveUser" :disabled="canSave">{{
					addUser ? 'Kullanıcı Ekle' : 'Kaydet'
				}}</r-btn>
			</r-flex>
		</r-flex>
	</r-panel>
	<r-panel
		title="Ayarlar"
		v-if="settingsPanel"
		v-model:isactive="settingsPanel"
		pos="center"
	>
		<r-flex align="flex-start center">
			<r-flex flx="3"
				><r-input v-model="settings.year" label="Çalışma Yıl"
			/></r-flex>
			<r-flex flx="3"
				><r-input v-model="settings.month" label="Çalışma Ay"
			/></r-flex>
			<r-flex flx="3"
				><strong>Çalışma Klasörü:</strong> {{ workingFolder }}</r-flex
			>
		</r-flex>
		<r-flex>
			<strong>PDF Yazı alanları:</strong>
		</r-flex>
		<r-flex align="flex-start center">
			<r-input v-model="settings.pdftelif" label="PDF Telif Yazı" />
		</r-flex>
		<r-flex align="flex-start center"
			><r-input v-model="settings.pdffooter" label="PDF Alt Yazı" />
		</r-flex>
		<r-flex>
			<strong>Destek Yazı:</strong>
		</r-flex>
		<r-flex align="flex-start center">
			<r-input v-model="settings.support" label="Destek Yazı" />
		</r-flex>
		<r-btn bgcolor="#15880d" @click="saveSettings">Kaydet </r-btn>
	</r-panel>
	<r-panel
		title="Hazır Kalıplar"
		v-if="hazirKaliplarPanel"
		v-model:isactive="hazirKaliplarPanel"
		pos="center"
	>
		<r-data-list
			:headdata="headHazirKalip"
			:listdata="hazirKaliplarList"
			:opt="optionsHazirKaliplar"
			:searchfilter="searchHazirKalip"
			v-model:qu="qHazirKalip"
			:disabledrag="false"
		>
		</r-data-list>
	</r-panel>
	<r-modal
		:title="passUser + ' için şifre değiştir'"
		v-if="passModal"
		v-model:isactive="passModal"
		width="35%"
	>
		<r-input
			v-model="passData.NewPassword"
			label="Yeni Şifre"
			type="password"
			class="r-mb-32"
		/>
		<r-input
			v-model="passData.NewPassword2"
			label="Yeni Şifre Tekrar"
			type="password"
		/>
		<template v-slot:otherbuttons>
			<r-btn
				bgcolor="#bb0202"
				@click="changePassword()"
				size="medium"
				:disabled="canChangePassword"
				>Değiştir</r-btn
			>
		</template>
	</r-modal>

	<r-modal
		title="Hazır Kalıp"
		v-if="hazirKalipModal"
		v-model:isactive="hazirKalipModal"
		:closeout="false"
		minWidth="750px"
		><vue-editor
			height="400px"
			:editorToolbar="adminToolBar"
			v-model="hazirKalipData.detail"
			:htmlBtn="true"
		/>
		<template v-slot:otherbuttons>
			<r-btn bgcolor="#15880d" @click="SaveHazirKalip" size="medium"
				>Kaydet</r-btn
			>
		</template>
	</r-modal>
</template>
<script>
import { onBeforeMount, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { trToUpper } from '@/mix/global'
import CryptoJS from 'crypto-js'
export default {
	setup() {
		const store = useStore()
		const listData = ref({})
		const userPanel = ref(false)
		const settingsPanel = ref(false)
		const userData = ref({})
		const editId = ref(null)
		const loaded = ref(false)
		const catList = ref({})
		const passwordAgain = ref('')
		const addUser = ref(false)
		const q = ref('')
		const settings = ref({})
		const passModal = ref(false)
		const passUser = ref('')
		const passData = ref({})
		const isWorking = ref(false)
		const endComment = ref('')
		const endModal = ref(false)
		const newUserList = ref('')
		const userModal = ref(false)
		const hazirKaliplarPanel = ref(false)
		const hazirKaliplarList = ref([])
		const qHazirKalip = ref('')
		const hazirKalipModal = ref(false)
		const hazirKalipData = ref({})

		const adminToolBar = ref({
			container: [
				['bold', 'italic', 'underline'],
				['clean'],
			],
		})

		const userTypeList = ref([
			{
				txt: 'Hoca',
				val: 0,
			},
			{
				txt: 'Akil',
				val: 1,
			},
			{
				txt: 'Admin',
				val: 6,
			},
		])
		const searchfilter = v => {
			return (
				(v.name && trToUpper(v.name).includes(trToUpper(q.value))) ||
				(v.email && trToUpper(v.email).includes(trToUpper(q.value)))
			)
		}
		const searchHazirKalip = v => {
			return (
				(v.detail && trToUpper(v.detail).includes(trToUpper(qHazirKalip.value)))
			)
		}
		const canChangePassword = computed(() => {
			if (
				passData.value.NewPassword !== undefined &&
				passData.value.NewPassword !== '' &&
				passData.value.NewPassword2 !== undefined &&
				passData.value.NewPassword2 !== '' &&
				passData.value.NewPassword === passData.value.NewPassword2
			)
				return false
			else return true
		})
		const checkPassword = computed(() => {
			if (addUser.value) {
				if (passwordAgain.value === userData.value.password) return true
				else return false
			} else {
				return true
			}
		})
		const canSave = computed(() => {
			if (addUser.value && !checkPassword.value) return true
			if (
				userData.value.type === 0 &&
				(userData.value.categoryId === undefined ||
					userData.value.categoryId === null)
			)
				return true
			if (
				userData.value.username === undefined ||
				userData.value.username === '' ||
				userData.value.email === undefined ||
				userData.value.email === ''
			)
				return true
			return false
		})
		const canSettingSave = computed(() => {
			if (
				(settings.value.year !== undefined && settings.value.year !== '') ||
				(settings.value.month !== undefined && settings.value.month !== '')
			)
				return false
			else return true
		})
		const workingFolder = computed(() => {
			let year = settings.value.year || ''
			let month = settings.value.month || ''
			return year + month
		})
		const headdata = computed(() => {
			return [
				{
					title: 'Id',
					style: { width: '5%', justifyContent: 'center' },
					key: 'id',
				},
				{
					title: 'Kullanıcı Adı',
					style: { width: '20%' },
					key: 'username',
				},
				{
					title: 'Adı',
					style: { width: '28%' },
					key: 'name',
				},
				{
					title: 'Eposta',
					style: { width: '27%' },
					key: 'email',
				},
				{
					title: 'Seviye',
					style: { width: '18%' },
					key: 'authorizations',
					cval: v => {
						return v.authorizations.length > 0 ? v.authorizations : ''
					},
				},
				{
					title: 'Görev',
					style: { width: '12%' },
					key: 'typeName',
				},
			]
		})
		const opts = ref({
			title: 'Kategoriler',
			count: true,
			buttons: {
				style: { width: '20%' },
				edit: {
					show: true,
					type: 'out',
					func: d => {
						EditLine(d)
					},
				},
				delete: {
					show: true,
					func: d => {
						deleteFunc(d)
					},
				},
				add: {
					show: true,
					type: 'out',
					func: d => {
						addFunc(d)
					},
				},
			},
		})
		const headHazirKalip = computed(() => {
			return [
				{
					title: 'Kalıp',
					style: { width: '80%' },
					key: 'detail',
					html: true,
				}
			]
		})
		const optionsHazirKaliplar = ref({
			title: 'Hazır Kalıplar',
			count: true,
			dragend: v => {
				DragEndHazirKalip(v)
			},
			buttons: {
				style: { width: '20%' },
				edit: {
					show: true,
					type: 'out',
					func: d => {
						EditHazirKalip(d)
					},
				},
				delete: {
					show: true,
					func: d => {
						DeleteHazirKalip(d)
					},
				},
				add: {
					show: true,
					type: 'out',
					func: d => {
						AddHazirKalip(d)
					},
				},
			},
		})

		const getList = async () => {
			let list = await store.dispatch('getDatas', {
				api: 'user/getusers',
				params: {
					page: 1,
					size: 300,
					order: 1,
				},
			})
			return list
		}
		const getUserData = async () => {
			let list = await store.dispatch('getDatas', {
				api: 'user/getuserdetails',
				params: {
					id: editId.value,
				},
			})
			return list
		}
		const getCatList = async () => {
			let list = await store.dispatch('getDatas', {
				api: 'categories/getsubcategories',
				params: {
					pid: 0,
				},
			})
			return list
		}
		const getSettings = async () => {
			let list = await store.dispatch('getDatas', {
				api: 'settings/getsettings',
			})
			return list
		}
		const addFunc = async () => {
			editId.value = null
			userData.value = {}
			userData.value.authorizations = []
			userPanel.value = true
			addUser.value = true
		}
		const saveUser = async () => {
			userData.value.authorizations = userData.value.authorizations.map(x =>
				x == 0 ? 0 : parseInt(x) || x
			)
			if (
				userData.value.categoryId !== null &&
				userData.value.categoryId !== 0 &&
				userData.value.categoryId !== undefined
			) {
				let branch = catList.value.data.filter(v => {
					return v.id === userData.value.categoryId
				})[0]
				userData.value.categoryName = branch.title + ' Eğitmeni'
			}
			if (!addUser.value) {
				delete userData.value.password

				await store.dispatch('postDatas', {
					api: 'user/updateuser',
					data: userData.value,
				})
				await store.dispatch('postDatas', {
					api: 'authorizations/authorizeuser',
					data: {
						types: userData.value.authorizations,
						userId: userData.value.id,
					},
				})
			} else {
				userData.value.password = CryptoJS.MD5(
					userData.value.password
				).toString()
				let userid = await store.dispatch('postDatas', {
					api: 'user/signup',
					data: userData.value,
				})
				await store.dispatch('postDatas', {
					api: 'authorizations/authorizeuser',
					data: {
						types: userData.value.authorizations,
						userId: parseInt(userid.data),
					},
				})
			}
			listData.value = await getList()
			userPanel.value = false
		}
		const deleteFunc = async d => {
			await store.dispatch('postDatas', {
				api: 'user/deleteuser',
				data: { userId: parseInt(d.row.id) },
			})
			listData.value = await getList()
		}
		const EditLine = async d => {
			editId.value = d.row.id
			let data = await getUserData()
			userData.value = data.data
			addUser.value = false
			userPanel.value = true
		}
		const openSettings = async () => {
			let data = await getSettings()
			data.data.forEach(o => {
				if (o.key === 'year') settings.value.year = o.value
				if (o.key === 'month') settings.value.month = o.value
				if (o.key === 'pdffooter') settings.value.pdffooter = o.value
				if (o.key === 'pdftelif') settings.value.pdftelif = o.value
				if (o.key === 'support') settings.value.support = o.value
			})
			settingsPanel.value = true
		}
		const saveSettings = () => {
			Object.keys(settings.value).forEach(async o => {
				await store.dispatch('postDatas', {
					api: 'settings/updatesetting',
					data: { key: o, value: settings.value[o] },
				})
			})
			settingsPanel.value = false
		}
		const changePasswordM = d => {
			passUser.value = d.name
			passData.value.UserId = d.id
			passModal.value = true
		}
		const changePassword = async () => {
			passData.value.NewPassword = CryptoJS.MD5(
				passData.value.NewPassword
			).toString()
			passData.value.NewPassword2 = CryptoJS.MD5(
				passData.value.NewPassword2
			).toString()
			await store.dispatch('postDatas', {
				api: 'user/changepassword',
				data: passData.value,
			})
			passData.value = {}
			passModal.value = false
			endModal.value = true
			setTimeout(() => {
				endModal.value = false
			}, 2000)
			endComment.value = 'Şifre başarılı şekilde değiştirildi.'
		}
		const getForumUsers = async () => {
			isWorking.value = true
			let newusers = await store.dispatch('getDatas', {
				api: 'user/migrateforumusers',
			})
			newusers.data.forEach(itm => {
				newUserList.value += `<strong>Eklendi:</strong> ${itm.username}<br>`
			})
			userModal.value = true
			setTimeout(() => {
				userModal.value = false
				newUserList.value = ''
			}, 10000)
			listData.value = await getList()
			isWorking.value = false
		}
		const GetHazirKaliplar = async () => {
			let list = await store.dispatch('getDatas', {
				api: 'presets/getallpresets',
				params: {
					page: 1,
					size: 500,
					order:0
				},
			})
			
			list.data = list.data.sort((a,b) => {
				return a.priority - b.priority
			})
			return list.data
		}
		const OpenHazirKaliplar = async () => {
			hazirKaliplarList.value = await GetHazirKaliplar()
			hazirKaliplarPanel.value = true
		}
		const AddHazirKalip = () => {
			hazirKalipData.value.id = 0
			hazirKalipData.value.detail = ''
			hazirKalipModal.value = true
		}
		const EditHazirKalip = d => {
			hazirKalipData.value = d.row
			hazirKalipModal.value = true
		}
		const DeleteHazirKalip = async d => {
			await store.dispatch('postDatas', {
				api: 'presets/deletepreset',
				data: {id:parseInt(d.row.id)},
			})
			hazirKaliplarList.value = await GetHazirKaliplar()
		}
		const SaveHazirKalip = async () => {
			await store.dispatch('postDatas', {
				api: 'presets/addpreset',
				data: hazirKalipData.value,
			})
			hazirKaliplarList.value = await GetHazirKaliplar()
			hazirKalipModal.value = false
		}
		const DragEndHazirKalip = async v => {
			let priorityList = []
			v.forEach((itm,i) => {
				priorityList.push({
					id:itm.id,
					priority:i+1
				})
			})
			await store.dispatch('postDatas', {
				api: 'presets/prioritizepresets',
				data: priorityList,
			})
			hazirKaliplarList.value = await GetHazirKaliplar()
		}
		onBeforeMount(async () => {
			listData.value = await getList()
			catList.value = await getCatList()
			loaded.value = true
		})
		return {
			headdata,
			opts,
			listData,
			userData,
			addFunc,
			userPanel,
			loaded,
			q,
			searchfilter,
			settingsPanel,
			openSettings,
			catList,
			passwordAgain,
			canSave,
			saveUser,
			addUser,
			canSettingSave,
			saveSettings,
			settings,
			workingFolder,
			changePasswordM,
			changePassword,
			passModal,
			passUser,
			passData,
			getForumUsers,
			isWorking,
			endComment,
			endModal,
			userTypeList,
			canChangePassword,
			newUserList,
			userModal,
			hazirKaliplarPanel,
			OpenHazirKaliplar,
			hazirKaliplarList,
			searchHazirKalip,
			qHazirKalip,
			optionsHazirKaliplar,
			headHazirKalip,
			hazirKalipModal,
			adminToolBar,
			SaveHazirKalip,
			hazirKalipData
		}
	},
}
</script>
